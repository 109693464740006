import React from "react";
import "./style.scss";
import Card from "../../common/Card";
import testicon from "../../assets/test.png";

const Services = () => {
  const services = [
    {
      image: testicon,
      heading: "Jyotish",
      content: "Personal consultation Vedic astrologer Jyotish",
      price: "Rs. 8,000",
    },
    {
      image: testicon,
      heading: "Ayurveda",
      content:
        "Master class on the basics of Ayurveda and type analysis doshas",
      price: "Rs. 5,000",
    },
    {
      image: testicon,
      heading: "Vastu",
      content:
        "A series of webinars on the practical use of basic Vastu rules for self-harmonizing your homeh",
      price: "Rs. 8,000",
    },
    {
      image: testicon,
      heading: "Yagya",
      content:
        "Participation in Yagya courses dedicated to creating favorable events in life, strengthening health and improving well-being",
      price: "Rs. 8,000",
    },
  ];
  return (
    <section className="services-section">
      <div className="heading-wrapper">
        <h1 className="large-subheading">Services</h1>
      </div>
      <div className="main-content">
        <div className="cards">
          {services.map((service) => {
            return (
              <Card
                image={service.image}
                heading={service.heading}
                content={service.content}
                price={service.price}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Services;
