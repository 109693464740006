import React from "react";
import "./style.scss";

const Card = (props) => {
  return (
    <div className="card">
      <img src={props.image} className="image" alt="image" />
      <h3 className="heading">{props.heading}</h3>
      <p className="content">{props.content}</p>
      {/* {props.price && <p className="price">{props.price}</p>} */}
    </div>
  );
};

export default Card;
