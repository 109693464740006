import React from "react";
import "./style.scss";
import Card from "../../common/Card";
import TestIcon from "../../assets/test.png";

const Welcome = () => {
  const headingContent = [
    "Discover the divine presence of Krishna at Shrinath Ashram, where spirituality comes alive through sacred Vedic practices and heartfelt devotion. Our ashram offers a peaceful haven for those seeking to deepen their connection with Krishna through a blend of timeless traditions.",
    "Immerse yourself in the divine presence of Krishn at Shrinath Ashram. Engage in transformative Yajnas (Vedic fire rituals), sacred Vedic practices, and heartfelt service to our cherished cows. Our ashram offers a serene space for spiritual growth, where devotion and ancient traditions intertwine to enrich your journey with Shri Krishn. Join us to experience divine grace and connection in a peaceful, nurturing environment.",
  ];

  const cardsData = [
    {
      image: TestIcon,
      heading: "Ashram",
      content: "This is a content and this is a random text so here is lalalla",
    },
    {
      image: TestIcon,
      heading: "Ashram",
      content: "This is a content and this is a random text so here is lalalla",
    },
    {
      image: TestIcon,
      heading: "Ashram",
      content: "This is a content and this is a random text so here is lalalla",
    },
  ];
  return (
    <section className="welcome">
      <div className="top-wrapper">
        <div className="heading-wrapper">
          <h5 className="small-heading">Welcome</h5>
          <h3 className="large-subheading">
            Experience Krishn at our ShrinathJi Ashram
          </h3>
        </div>
        <div className="content">
          {headingContent.map((heading) => (
            <p>{heading}</p>
          ))}
        </div>
      </div>
      <div className="content-wrapper">
        <div className="cards-wrapper">
          {cardsData.map((card) => {
            return (
              <Card
                image={card.image}
                heading={card.heading}
                content={card.content}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Welcome;
