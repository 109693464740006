import React from "react";
import "./style.scss";

const ContactUs = () => {
  return (
    <section className="contact-section">
      <div className="bg-layer" />
      <div className="container">
        <h1 className="heading">
          For consultation, write to us by email and indicate the service you
          are interested in in the subject line.
        </h1>
        <div className="mail">
          <a href="mailto:admin@shrinathjikarmakandsansthan.com">
            admin@shrinathjikarmakandsansthan.com
          </a>
        </div>
        <div className="social-media">
          <a href="tel:+918171430756">+918171430756</a>
        </div>
        <p
          className="social-media"
          onClick={() => {
            window.open(
              "https://wa.me/8171430756/?text=Hi. My name is ",
              "_blank" // <- This is what makes it open in a new window.
            );
          }}
        >
          Whatsapp
        </p>
        <p
          className="social-media"
          onClick={() => {
            window.open(
              " https://t.me/+918171430756",
              "_blank" // <- This is what makes it open in a new window.
            );
          }}
        >
          Telegram
        </p>

        <div className="privacy">
          <p>PRIVACY POLICY</p>
          <p>Public offer agreement for the provision of training services</p>
          <p>Public offer agreement for the provision of consulting services</p>
        </div>
        <div className="copyright">
          <p>Personal data and their processing</p>
          <p>© Shrinathji Karmakand Sansthan, {new Date().getFullYear()}</p>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
