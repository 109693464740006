import React from "react";
import "./style.scss";
import Welcome from "../Welcome";
import BackgroundImg from "../../assets/skks-bg.jpg";
import { ReactComponent as Arrow } from "../../assets/right_arrow.svg";

const HomePage = () => {
  const goToSection = () => {
    const element = document.getElementsByClassName("goal")[0];
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  return (
    <>
      <section className="main">
        <div className="main-bg" />
        {/* <img src={BackgroundImg} className="main-bg" /> */}
        <div className="tag-line">
          <h1 className="heading">Shrinathji Karmakand Sansthan</h1>
          <p>
            "Faith Becomes Lame, When it Ventures Into Matters Pertaining to
            Reason!"
          </p>
        </div>
        <div className="down_arrow" onClick={goToSection}>
          <Arrow />
        </div>
      </section>
      <section className="goal">
        <div>
          <h1 className="heading">Our Goal</h1>
          <p className="content">
            We aim to communicate the profound value of Vedic knowledge and
            empower individuals to integrate its teachings into their daily
            lives. By doing so, we aspire to ignite a transformative process
            that begins at the level of families and gradually extends to cities
            and society as a whole. Our vision is to create a ripple effect,
            where the wisdom of the Vedas fosters harmony, well-being, and
            spiritual growth across communities. Through education, outreach,
            and practical guidance, we seek to make these ancient teachings
            accessible and relevant to modern life, ultimately contributing to a
            more enlightened and connected world.
          </p>
        </div>
      </section>
      <Welcome />
    </>
  );
};
export default HomePage;
