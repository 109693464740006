
import './App.scss';
import Navbar from './common/Navbar';
import ContactUs from './components/ContactUs';
import HomePage from './components/HomePage';
import Services from './components/Services';
import "./fonts/Tilda-Sans-Font-Family/stylesheet.css";

function App() {
  return (
    <div className="App">
      {/* <Navbar /> */}
      <HomePage />
      <Services />
      <ContactUs />
    </div>
  );
}

export default App;
