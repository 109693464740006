import React from "react";
import "./style.scss";
import Logo from "../../assets/shrinathjikks.png";

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="logo">
        {/* ॐ */}
        <img src={Logo} />
        {/* <h1 className="text">Shrinathji Karmakand Sansthan</h1> */}
        </div>
    </nav>
  );
};

export default Navbar;
